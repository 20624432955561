import { createRouter, createWebHistory } from 'vue-router';

const TelaCarregamento = () => import('../views/TelaCarregamento.vue');
const TelaLoginManager = () => import('../views/TelaLoginManager.vue');
const TelaMenu = () => import('../views/TelaMenu.vue');
const TelaImportacoes = () => import('../views/Importacoes/TelaImportacoes.vue');
const TelaImportacoesBases = () => import('../views/Importacoes/TelaImportacoesBases.vue');
const ProdutosBloqueados = () => import('../views/Configuracoes/ProdutosBloqueados.vue');
const RegrasRebaixa = () => import('../views/Configuracoes/RegrasRebaixa.vue');
const RegrasRetirada = () => import('../views/Configuracoes/RegrasRetirada.vue');
const GestaoLojas = () => import('../views/Gestao/GestaoLojas.vue');
const GestaoUsuarios = () => import('../views/Gestao/GestaoUsuarios.vue');
const GestaoComercial = () => import('../views/Gestao/GestaoComercial.vue');
const Enderecamento = () => import('../views/Visao/TelaEnderecamento.vue');
const TelaRecebimentoCD = () => import('../views/Transferencias/TelaRecebimentoCD.vue');
const TelaExpedicaoCD = () => import('../views/Transferencias/TelaExpedicaoCD.vue');

const routes = [
    {
        path: '/',
        name: 'login',
        component: TelaLoginManager,
        meta: {
            title: 'semHeader',
            hasArrow: false,
            hasNoRefresh: true,
        },
    },
    {
        path: '/carregamento',
        name: 'carregamento',
        component: TelaCarregamento,
        meta: { title: 'semHeader', hasArrow: false, hasNoRefresh: true },
    },
    {
        path: '/menu',
        name: 'menu',
        component: TelaMenu,
        meta: {
            title: 'Painel de Gerenciamento',
            hasArrow: false,
            hasNoRefresh: true,
            hasMenu: false,
        },
    },
    {
        path: '/tela/importacoes',
        name: 'tela_importacoes',
        component: TelaImportacoes,
        meta: {
            title: 'Painel de Gerenciamento - Importação',
            hasArrow: true,
            hasNoRefresh: true,
            hasMenu: true,
        },
    },
    {
        path: '/tela/importacoes-bases',
        name: 'tela_importacoes_bases',
        component: TelaImportacoesBases,
        meta: {
            title: 'Painel de Gerenciamento - Importação',
            hasArrow: true,
            hasNoRefresh: true,
            hasMenu: true,
        },
    },
    {
        path: '/produtos/bloqueados',
        name: 'produtos_bloqueados',
        component: ProdutosBloqueados,
        meta: {
            title: 'Painel de Gerenciamento - Produtos Bloqueados',
            hasArrow: true,
            hasNoRefresh: true,
            hasMenu: true,
        },
    },
    {
        path: '/regras/rebaixa',
        name: 'regras_rebaixa',
        component: RegrasRebaixa,
        meta: {
            title: 'Painel de Gerenciamento - Regras Rebaixa',
            hasArrow: true,
            hasNoRefresh: true,
            hasMenu: true,
        },
    },
    {
        path: '/regras/retirada',
        name: 'regras_retirada',
        component: RegrasRetirada,
        meta: {
            title: 'Painel de Gerenciamento - Regras Retirada',
            hasArrow: true,
            hasNoRefresh: true,
            hasMenu: true,
        },
    },
    {
        path: '/gestao/lojas',
        name: 'gestao_lojas',
        component: GestaoLojas,
        meta: {
            title: 'Painel de Gerenciamento - Gestão de Lojas',
            hasArrow: true,
            hasNoRefresh: true,
            hasMenu: true,
        },
    },
    {
        path: '/gestao/usuarios',
        name: 'gestao_usuarios',
        component: GestaoUsuarios,
        meta: {
            title: 'Painel de Gerenciamento - Gestão de Usuários',
            hasArrow: true,
            hasNoRefresh: true,
            hasMenu: true,
        },
    },
    {
        path: '/gestao/comercial',
        name: 'gestao_comercial',
        component: GestaoComercial,
        meta: {
            title: 'Painel de Gerenciamento - Gestão de Usuários Comercial',
            hasArrow: true,
            hasNoRefresh: true,
            hasMenu: true,
        },
    },
    {
        path: '/visao/enderecamento',
        name: 'enderecamento',
        component: Enderecamento,
        meta: {
            title: 'Painel de Gerenciamento CD',
            hasArrow: true,
            hasNoRefresh: true,
            hasMenu: true,
        },
    },
    {
        path: '/transferencias/recebimento',
        name: 'tela_recebimento_cd',
        component: TelaRecebimentoCD,
        meta: {
            title: 'Painel de Gerenciamento - Recebimento CD',
            hasArrow: true,
            hasNoRefresh: true,
            hasMenu: true,
        },
    },
    {
        path: '/transferencias/expedicao',
        name: 'tela_expedicao_cd',
        component: TelaExpedicaoCD,
        meta: {
            title: 'Painel de Gerenciamento - Expedição CD',
            hasArrow: true,
            hasNoRefresh: true,
            hasMenu: true,
        },
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0 };
        }
    },
});

router.beforeEach((to, from, next) => {
    let currentRoute = to.name;

    if (currentRoute == 'login') {
        if (currentRoute == 'login') {
            next();
        } else {
            router.push({ name: 'login' });
        }
    } else if (currentRoute !== 'login') {
        let user = sessionStorage.getItem('usuario');

        if (user) {
            next();
        } else {
            router.push({ name: 'login' });
        }
    } else {
        next();
    }
});

export default router;
