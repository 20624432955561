import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
// import IdleVue from 'idle-vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faCircleLeft,
    faClipboardList,
    faHandshake,
    faBoxesPacking,
    faIndustry,
} from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp, faYoutube } from '@fortawesome/free-brands-svg-icons';
import {
    faArrowRightFromBracket,
    faBan,
    faTag,
    faClockRotateLeft,
    faUserGroup,
    faCartShopping,
    faShop,
    faMagnifyingGlassArrowRight,
    faSpinner,
    faPenToSquare,
    faUpRightAndDownLeftFromCenter,
    faMinus,
    faBoxesStacked,
    faChevronDown,
    faChevronUp,
    faClipboardCheck,
    faCubesStacked,
    faTruckArrowRight,
    faSackDollar,
    faMagnifyingGlass,
} from '@fortawesome/free-solid-svg-icons';
library.add(
    faCircleLeft,
    faClipboardList,
    faHandshake,
    faBoxesPacking,
    faIndustry,
    faWhatsapp,
    faArrowRightFromBracket,
    faBan,
    faYoutube,
    faTag,
    faClockRotateLeft,
    faUserGroup,
    faCartShopping,
    faShop,
    faMagnifyingGlassArrowRight,
    faSpinner,
    faPenToSquare,
    faUpRightAndDownLeftFromCenter,
    faMinus,
    faBoxesStacked,
    faChevronDown,
    faChevronUp,
    faClipboardCheck,
    faCubesStacked,
    faTruckArrowRight,
    faSackDollar,
    faMagnifyingGlass,
);

createApp(App)
    .use(store)
    .use(router)
    // .use(IdleVue, {
    //     store,
    //     idleTime: 900000,
    //     startAtIdle: false,
    // })
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app');
